.myOrder-sec1{
    padding: 30px;
    box-shadow: 0px 0px 20px 0px #E8E8E8B2;
    border-radius: 20px;
    width: 100%;
}
.book-order{
    text-align: right;
}
.book-order i {
    color: orange;
    padding-right: 30px;
}
.Order-detail{
    border-radius: 20px;
background: #FFF;
box-shadow: 0px 0px 20px 0px rgba(232, 232, 232, 0.70);
padding: 20px 0px;
}
.Order-detail table {
    width: 100%;
}
.Order-detail th {
    border-bottom: 1PX solid #EAEAEA;
    color: #494949;
font-family: Kanit;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
 text-align: center;
}
.Order-detail td {
    border-bottom: 1PX solid #EAEAEA;
    color: #494949;
    font-family: Kanit;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding: 10px;
    text-align: center;
}
.Order-detail i {
    color: orange;
}