.sidebar-detail {
  border-radius: 0px 40px 30px 0px;
  background-color: #2f154c;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  border-radius: 30px 0px 0px 30px;
  background: #fff;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link a {
  color: #494949;
  font-family: Kanit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.54px;
  text-decoration: none;
}
.sub-menu li a  {
text-decoration: none;
}

.sidebar-detail a {
  color: #94a3b8;
  font-family: Kanit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.54px;
}
.sidebar-img {
  text-align: center;
}
.sidebar-img img {
  padding: 30px 0px 40px 0px;
}
.AllCourses-Available button {
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  background: #fff !important;
  box-shadow: 0px 0px 20px 0px rgba(232, 232, 232, 0.7);
  color: #494949;
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
  padding: 5px 15px 5px 15px;
  margin-top: 20px;
}
.sdhead{
  margin-left: 10px;
}
.thumset{
  margin-top: 10px;
  max-width: 200px;
}