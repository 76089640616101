.quizz_details {
  box-shadow: 0px 0px 15px 0px #0000001A;


}


.quiz-card {
  border: 1px solid #eaeaea;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.quiz-header {

  display: flex;
  align-items: center;
}

.quiz-header img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.quiz-info {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.quiz-info div {
  text-align: center;
}

.star-rating {
  margin-left: auto;
  color: #000;
  font-family: Satoshi;
  font-size: 14.67px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.02em;
  text-align: left;

}

p {
  margin: 0;
}

.sessionBorder {
  border-top: 0.5px solid #EDEDED
}

.queizImage {
  width: 70px;
  height: 70px;
  object-fit: cover;
  display: block;
}

/* question css  */
.left_contnet {
  padding:10px;
  box-shadow: 0px 0px 25px 0px #00000033;
  width: 330px;
height: 858px;
display: flex;
flex-direction: column;


gap: 4px;
border-radius: 0px 10px 10px 0px;
opacity: 0px;
}

.questionLIst {
  width: 310px;
  height: 252px;
 
gap: 0px;
border-radius: 8px 0px 0px 0px;
opacity: 0px;

}

.quizzes_time_number_text {
  font-family: Kanit;
  font-size: 22px;
  font-weight: 500;
  line-height: 32.89px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #1D0D2F;
  padding-bottom: 5px;
  border-bottom: 1px solid #BDB1FF
}

.chooseQustion {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.93px;
  text-align: left;
  color: #5B577A;

}

.questionLIstOfNumber {
  padding:10px 0;
  width: 310px;
  height: auto;
gap:10px;
border: 1px solid #D3D3D3;
  border-radius: 10px 0px 0px 0px;
  opacity: 0px;

}

.questinNumberActive {
  background: linear-gradient(180deg, #15BB30 0%, #17B730 100%);
  color: #fff;
  transition: all 0.3s ease;
}

.questinNumberActive:hover {
  background: linear-gradient(180deg, #17B730 0%, #15BB30 100%);
}

.questionNumberBtn {
  color: #000;
  background: linear-gradient(180deg, #EFEFEF 0%, #D3D3D3 100%);
  transition: all 0.3s ease;
}

.questionNumberBtn:hover {
  background: linear-gradient(180deg, #D3D3D3 0%, #EFEFEF 100%);
}

.questinNumberActive,
.questionNumberBtn {
  width: 50px;
  height: 50px;
}

.timer {
  width: 137px;
height: 38px;
display: flex;
justify-content: center;
align-items: center;
gap:8px;
border-radius: 30px ;
opacity: 0px;
background: #2F154C;



}
.timer span {
  font-family: Kanit;
font-size: 16px;
font-weight: 400;
line-height: 23.92px;
letter-spacing: 0.03em;
text-align: left;
color:#F37321;
}