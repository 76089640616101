.calendar-container {
  width: 100%;
  padding: 16px;
  border-radius: 20px;
  background: white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #4a4a4a;
  font-weight: bold;
}

.calendar-header button {
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: #494949;
  cursor: pointer;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  color: #FFA56B  ;
  font-weight: bold;
  margin-top: 10px;
  font-size: 12px;
}

.calendar-dates {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  margin-top: 10px;
}

.calendar-date {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  padding: 5px;
  margin: 3px 0;
  border-radius: 50%;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s;
  font-size: 14px;
  color: #4A5660;
}

.calendar-date:hover {
  background-color: #f1f1f1;
}

.calendar-date.selected {
  background-color: #F04D23;
  color: white;
}

.calendar-date.empty {
  background-color: transparent;
  cursor: default;
}

.calendar-header span {
  color: #4a4a4a;
}

.calendar-container button:focus {
  outline: none;
}
.leftSideBtn button{
font-size: 25px;
margin: 5px;
}
.calendar-header span{
  font-family: Kanit;
font-size: 14px;
font-weight: 400;
line-height: 14px;
text-align: center;
color: #4A5660;

}

@media (max-width: 768px) {
  .calendar-container {
    width: 100%;
  }
}