.cardTopSession {
    background-color: #F7F9FA;
}
p
{margin:0}

.Ratting_Font_Size {
    font-size: 12px;
}
.text-weight{
    font-weight: 600;
}