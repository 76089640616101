@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.banner-sec {
  background: url(../images/back.png) center top;
  background-size: 90% auto;
  /* Set the width to auto and height to cover the full container */
  background-repeat: no-repeat;
  height: 509px;
  padding-top: 50px;
  padding-bottom: 70px;
  position: relative;
  z-index: 7;
}

.error_message {
  color: #c73e1c;
}

.register-btn {
  color: #828282 !important;
  font-family: Kanit !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  border-radius: 0px 0px 0px 20px !important;
  background-color: #dfdfdf !important;
  width: 356px !important;
  height: 70px !important;
  flex-shrink: 0 !important;
  text-align: left !important;
}

.signIn-btn {
  background-color: #f37321 !important;
  border-radius: 0px 0px 10px 0px !important;
  color: #fff !important;
  font-family: Kanit !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  width: 332.6px !important;
  height: 70px !important;
  flex-shrink: 0 !important;
  text-align: right !important;
  border: none !important;
  text-decoration: none !important;
}

.password-eyes {
  position: absolute;
  right: 16px;
  top: 5px;
  cursor: pointer;
  color: grey;
  font-size: 18px;
}

/* ///logn */

/* .login-sec {
  background: url(../../assests/images/logn-background.png);
  padding: 100px;
} */
.login-sec {
  background: url(../../assests/images/logn-background.png);
  padding: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.login-detail {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(232, 154, 154, 0.25);
  width: 60%;
  margin: auto;
}

.login-part {
  margin: 10px auto 0px auto;
}

.login-part h4 {
  color: #5e5e5e;
  font-family: Judson;
  font-size: 46px;
  font-style: normal;
  font-weight: 400;
  line-height: 62px;
  /* 134.783% */
  padding: 0px 20px;
  margin-bottom: 5px !important;
}

.LoginForm-div {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap:10px;
}

.LoginForm-div h4 {
  text-align: center;
  font-weight: 500;
  font-size: 32px;
  color: #112b4f;
}

.LoginForm-div label {
  font-weight: 500;
  font-size: 16px;
  color: #676767;
}

.LoginForm-div input {
  border: none;
  border-bottom: 1px solid #848484;
  border-radius: 0;
  padding: 0;
}

.LoginForm-div input {
  border: none;
  border-bottom: 1px solid #cccccc;
  border-radius: 0;
  padding: 4px 10px;

}

.LoginForm-div input:focus {
  outline: 0;
  box-shadow: none;
}

.LoginForm-div .password::placeholder,
.LoginForm-div .email::placeholder,
.LoginForm-div .name::placeholder {
  color: #828282;
  font-family: Kanit;
  font-size: 30px;
  font-style: normal;
  font-weight: 275;
  line-height: normal;
}



.password-eyes {
padding-bottom: 10px;
  cursor: pointer;
  color: grey;
  font-size: 18px;
}

.Forgot-password {
  text-align: center;
  margin-bottom: 40px;
}

.Forgot-password a {
  text-decoration: none;
  color: #828282;
  font-family: Kanit;
  font-size: 30px;
  font-style: normal;
  font-weight: 275;
  line-height: normal;
}

.loginPage-Button {
  margin: 0px !important;
  padding: 0px !important;
}

.loginPage-button1 {
  color: #828282;
  font-family: Kanit;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 0px 0px 0px 20px;
  background: #dfdfdf;
  width: 380px;
  height: 70px;
  flex-shrink: 0;
  text-align: left;
  border: none;
}

.loginPage-button2 {
  border: none;
  border-radius: 0px 0px 10px 0px;
  background: #f37321;
  color: #fff;
  font-family: Kanit;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 328.6px;
  height: 70px;
  flex-shrink: 0;
  text-align: right;
}

/* // admin pannel css */

* .adminLoginPage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  /* background: url(../images/back.png); */
  /* background:linear-gradient(to left, green, white); */
  background-size: cover;
  background-position: center;
}

.wrapper {
  width: 420px;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  border-radius: 10px;
  padding: 30px 40px;
  background-color: purple;
}

.wrapper h1 {
  font-size: 36px;
  text-align: center;
}

.wrapper .input-box {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 30px 0;
}

.input-box input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 40px;
  font-size: 16px;
  color: #fff;
  padding: 20px 45px 20px 20px;
}

.input-box input::placeholder {
  color: #fff;
}

.input-box i {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
}

.wrapper .remember-forgot {
  display: flex;
  justify-content: space-between;
  font-size: 14.5px;
  margin: -15px 0 15px;
}

.remember-forgot label input {
  accent-color: #fff;
  margin-right: 3px;
}

.remember-forgot a {
  color: #fff;
  text-decoration: none;
}

.remember-forgot a:hover {
  text-decoration: underline;
}

.wrapper .btn {
  width: 100%;
  height: 45px;
  border-radius: 40px;
  border: none;
  outline: none;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 16px;
  color: #333;
  font-weight: 600;
}

.wrapper .register-link {
  text-align: center;
  font-size: 14.5px;
  margin: 20px 0 15px;
}

.register-link p a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

.register-link p a:hover {
  text-decoration: underline;
}

.page-wrapper {
  display: flex;
}

.body-wrapper {
  display: flex;
  flex: 1;
}

.main-content {
  flex: 1;
  background-image: url("../images/background.png");
  background-size: cover;
}

/* course details  */
.card {
  margin-top: 20px;
  margin-bottom: 1.875rem;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0px solid transparent;
  border-radius: 0.25rem;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  height: calc(100% - 30px);
}

.col-lg-12.col-md-12.col-sm-12.button-container {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.page-titles {
  padding: 0.9375rem 1.25rem;
  background: #fff;
  margin-bottom: 30px;
  border-radius: 5px;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

/* /// all course list  */
.About-course {
  display: flex;
  align-items: center;
  position: relative;
}

.coursedetail {
  border: 1px solid #cccccc;
  background-color: #ffffff;
  margin-top: 20px;
  border-radius: 15px;
  padding: 10px 20px 10px 20px;
}

.coursedetail h6 {
  color: #494949;
  font-family: Kanit;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.66px;
}

/* .coursedetail img {
  height: 50px;
  margin-top: 60px;
} */
.other-detail {
  display: flex;
  align-items: center;
}

.other-detail img {
  height: 16px;
  padding: 0px 10px 0px 0px;
}

.other-detail p {
  color: #2d2e2d;
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 275;
  line-height: normal;
  letter-spacing: 0.48px;
  padding: 25px 50px 0px 0px;
}

.other-detail span {
  color: #2d2e2d;
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 275;
  line-height: normal;
  letter-spacing: 0.48px;
}

.rating {
  display: flex;
}

.rating p {
  color: #2d2e2d;
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
}

.rating img {
  height: 20px;
  padding: 5px 0px 0px 0px;
}

.rating span {
  color: #2d2e2d;
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 275;
  line-height: normal;
  letter-spacing: 0.48px;
}

.allcourse-sec {
  margin-top: 20px;
  margin-bottom: 1.875rem;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0px solid transparent;
  border-radius: 0.25rem;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  height: calc(100% - 30px);
}

.selected-item {
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(232, 232, 232, 0.7);
}

.editIcon {
  position: absolute;
  top: 10px;
  /* Adjust the top position as needed */
  right: 10px;
  /* Adjust the right position as needed */
  z-index: 1;
  /* Ensure icons appear above the image */
}

.btn.btn-outline-success.editIcon {
  margin: 0px 48px 2px 6px;
  padding: 3px 5px;
  margin-right: 34px;
}

.btn.btn-outline-danger.editIcon {
  padding: 3px 5px;
}

.selectedImage {
  /* position: absolute; */
  margin-top: 20px !important;
  margin: 2px 10px 0px;
  max-width: 90px;
}

.password {
  position: absolute;
  top: calc(100% + -28px);
  right: 10px;
  cursor: pointer;
}

.password i {
  font-size: 1.25em;
  /* Adjust the icon size as needed */
  color: #555;
  /* Adjust the icon color */
}

.form-group {
  position: relative;
}

/* /// all student list  */
.card-header {
  border-color: #eaeaea;
  position: relative;
  background: transparent;
  padding: 1.25rem 1.25rem 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0px;
}

.dataTables_wrapper .dataTables_filter {
  float: right;
  text-align: right;
}

table.dataTable thead th {
  color: #454545;
  font-weight: 600;
}

.imglogoset {
  max-width: 50px;
}

/* /// all Instructor  */
.btn.btn-outline-danger.actionIcon {
  font-size: 12px;
  margin: 5px;
  padding: 3px 5px;
}

.btn.btn-outline-success.actionIcon {
  font-size: 12px;
  margin: 5px;
  padding: 3px 5px;
}

.red-text {
  color: red;
  font-size: 20px;
  margin-left: 3px;
}

.otherdetail h6 {
  color: #494949;
  font-family: Kanit;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.66px;
}

.otherdetail {
  display: flex;
  /* Use flexbox for layout */
  align-items: center;
  /* Align items vertically in the center */
}

.otherdetail img {
  width: 24px;
  /* Adjust width as needed */
  height: 24px;
  /* Adjust height as needed */
  margin-right: 5px;
  /* Add margin for spacing */
}

.left {
  display: flex;
  /* Use flexbox to arrange left elements */
  align-items: center;
  /* Align items vertically in the center */
  margin-right: 20px;
  /* Add margin for spacing */
}

.right {
  display: flex;
  /* Use flexbox to arrange right elements */
  align-items: center;
  /* Align items vertically in the center */
}

.otherdetail p,
span {
  margin: 0;
  /* Reset default margins */
  font-size: 20px;
}

.imagebox {
  font-size: 16px;
}

.imagebox img {
  height: 50px;
  margin-left: 50px !important;
  margin-top: 60px !important;
}

.allrating {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.allrating p {
  color: #2d2e2d;
  font-family: Kanit;
  font-size: 22px;
  margin-left: 10px;
  margin-top: 20px;
}

.allrating img {
  height: 20px;
  padding: 2px 0px 0px 0px;
  margin-top: 2px;
  margin-left: 8px;
}

.allrating span {
  color: #2d2e2d;
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 275;
  line-height: normal;
  letter-spacing: 0.48px;
}

.value-box {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 5px;
  margin: 5px 0;
  background-color: #f5f5f5;
  justify-content: space-between;
}

.delete-icon {
  cursor: pointer;
  color: red;
}

.icon-container {
  display: flex;
}

/* Adjust margin or padding for spacing */
.fa {
  margin-right: 5px;
  /* Adjust as needed */
}

i.fa-solid.fa-file-pdf {
  font-size: 30px;
}

a.btn.addnew {
  color: #fff;
  border-radius: 5.891px;
  background: linear-gradient(0deg, #ee643c 58.97%, #f4933d 125.97%);
}

input.form-check-input {
  height: 33px;
  width: 83px !important;
}

table.table.table-bordered.mytable {
  margin-top: 20px;
}

.borderedclr {
  /* background-color: #F17218 !important; */
  text-align: center;
}

.btn.addnew {
  color: #fff;
  border-radius: 5.891px;
  background: linear-gradient(0deg, #ee643c 58.97%, #f4933d 125.97%);
}

/* span.btn.btnclr {
  color: white;
  background-color: #F17218 !important;
} */
.hiring-sec {
  background-color: #f8f4eb;
  padding-top: 50px;
  padding-bottom: 50px;
}

th.borderedclr {
  color: #fff !important;
  border-radius: 5px;
  background: linear-gradient(95deg, #f27017 0%, #e28825 103.41%);
  font-size: 20px;
}

.topicslabel {
  margin-top: 30px;
  text-align: center;
}

.form-Label {
  margin-top: 10px;
  color: #000;
  font-size: 20px;
  font-weight: 100px;
}

.selectedProfile {
  max-width: 50px;
  margin-top: 20px;
  margin-left: 20px;
}

/* /// home page css  */
.home-sec1 {
  /* background: linear-gradient(90deg, #002fed 0%, #32f2eb 100%); */
  padding: 50px;
  background-repeat: no-repeat;
}

.home-mainContent {
  padding: 50px 0px 0px 0px;
}

.home-mainContent p {
  color: #fff;
  font-family: Judson;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px;
  /* 134.783% */
}

.home-mainContent span {
  background: linear-gradient(92deg, #fdb813 30.11%, #f37321 101.17%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Judson;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px;
}

.home-mainContent h6 {
  color: #fff;
  font-family: Kanit;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.home-mainContent button {
  border-radius: 7px;
  background: linear-gradient(93deg, #7bae38 1.32%, #aee358 100%);
  color: #fff;
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 15px 10px 15px;
}

.myModal-body {
  padding: 0px !important;
}

.modal-img {
  padding: 0px !important;
}

.modal-img img {
  width: 250px !important;
  height: 350px !important;
  flex-shrink: 0;
}

.modal-detail h6 {
  color: #494949 !important;
  font-family: Kanit !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  margin-top: 50px;
  text-align: center;
}

.modal-detail p {
  color: #5e5e5e;
  font-family: Kanit;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  margin-top: 20px;
}

.modal-detail i {
  float: right;
  margin: 10px 0px 0px 0px;
}

.whatsapp {
  position: relative;
}

.whatsapp button {
  border: none;
  width: 220px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 7px;
  background: #0fd346;
  color: #fff;
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.whatsapp i {
  position: absolute;
  color: white;
  top: 2px;
  left: 30px;
}

.phone {
  position: relative;
}

.phone button {
  border: none;
  width: 220px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 7px;
  background: #0088fc;
  color: #fff;
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.phone i {
  position: absolute;
  color: white;
  top: 2px;
  left: 20px;
}

.home-sec2 {
  background: #faf7f2;
}

.Instantly {
  padding: 50px 0px 0px 0px;
  text-align: center;
}

.Instantly P {
  color: #494949;
  font-family: Judson;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px;
  /* 134.783% */
}

.Instantly span {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  font-family: Judson;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px;
  /* 134.783% */
  background: linear-gradient(91deg, #e9aa00 45.88%, #f27017 100.92%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Instantly h6 {
  color: #494949;
  font-family: Kanit;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.home-courses {
  border-bottom: 1px solid #dcdcdc;
  margin-top: 50px;
}

.home-courses p {
  /* color: #5e5e5e;
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px; */
  /* padding-bottom: 14px; */
}

.home-courses span {
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
  padding-bottom: 14px;
}

.showUpcoming {
  border-bottom: 2px solid orange;
  color: #f6891f;
}

.About-course {
  display: flex;
  align-items: center;
}

.course-detail {
  border: 1px solid #cccccc;
  background-color: #ffffff;
  margin-top: 20px;
  border-radius: 15px;
  padding: 10px 20px 10px 20px;
}

.course-detail h6 {
  color: #494949;
  font-family: Kanit;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.66px;
}

.course-detail img {
  height: 50px;
  /* padding: 0px 20px 0px 0px; */
}

.other-detail {
  display: flex;
  align-items: center;
}

.other-detail img {
  height: 16px;
  padding: 0px 10px 0px 0px;
}

.other-detail p {
  color: #2d2e2d;
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 275;
  line-height: normal;
  letter-spacing: 0.48px;
  padding: 25px 50px 0px 0px;
}

.other-detail span {
  color: #2d2e2d;
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 275;
  line-height: normal;
  letter-spacing: 0.48px;
}

.rating {
  display: flex;
}

.rating p {
  color: #2d2e2d;
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
}

.rating img {
  height: 20px;
  padding: 5px 0px 0px 0px;
}

.rating span {
  color: #2d2e2d;
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 275;
  line-height: normal;
  letter-spacing: 0.48px;
}

.view-button {
  text-align: center;
  margin: 20px 0px 20px 0px;
  padding-bottom: 20px;
}

.view-button button {
  color: #fff;
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  border-radius: 7px;
  background: linear-gradient(93deg, #f17218 1.33%, #f19e49 105.93%);
  padding: 10px 35px 10px 35px;
}

.Recognized p {
  text-align: center;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  font-family: Judson;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px;
  /* 134.783% */
  background: linear-gradient(91deg, #e9aa00 45.88%, #f27017 100.92%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Recognized img {
  width: 100%;
  margin-bottom: 80px;
}

.teacher-detail h6 {
  color: #494949;
  font-family: Judson;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px;
  /* 134.783% */
}

.teacher-detail span {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background: linear-gradient(91deg, #e9aa00 45.88%, #f27017 100.92%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.paragraph {
  color: #5e5e5e;
  font-family: Kanit;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.placed-companies {
  border-radius: 10px;
  border: 1px solid #ffc68f;
  background: #fff2e5;
  box-shadow: 0px 0px 20px 0px rgba(255, 232, 210, 0.5);
  backdrop-filter: blur(1px);
  padding: 10px 5px 5px 10px;
}

.placed-companies span {
  color: #494949;
  font-family: Kanit;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.78px;
  padding: 15px 0px 0px 10px;
}

.placed-companies p {
  color: #494949;
  font-family: Kanit;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.companies-quantity {
  display: flex;
  align-items: center;
}

.home-sec4 {
  background: #faf7f2;
}

.inspireOne {
  padding: 0px !important;
  margin: 0px !important;
}

.inspire p {
  color: #494949;
  font-family: Judson;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 146px;
  /* 134.783% */
  text-align: center;
}

.inspire span {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background: linear-gradient(91deg, #e9aa00 45.88%, #f27017 100.92%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Inspire1 {
  border-radius: 15px 0px 0px 15px;
  background: #6715a8;
  padding: 20px 10px 0px 10px;
  height: 420px;
  overflow: hidden;
}

.Inspire1 h6 {
  color: #fff;
  font-family: Kanit;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.78px;
  width: 70%;
}

.Inspire1 p {
  color: #e7e7e7;
  font-family: Kanit;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 80%;
  margin-bottom: 0px !important;
}

.Inspire1 span {
  color: #dfdfdf;
  font-family: Kanit;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.66px;
}

.inspire-detail {
  display: flex;
  align-items: center;
}

.inspire-detail p {
  width: 100%;
}

.inspire-detail img {}

.inspireTwo {
  padding: 0px !important;
}

.inspire2 {
  background: #04366b;
  padding: 20px 0px 0px 0px;
  height: 420px;
}

.inspire2 img {
  width: 100%;
}

.inspire2 p {
  color: #fff;
  font-family: Kanit;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.78px;
  padding: 0px 50px 34px 0px;
}

.inspireThree {
  padding: 0px !important;
}

.inspire3 {
  background: #000c4e;
  padding: 20px 0px 0px 0px;
  height: 420px;
}

.inspire3 img {
  width: 100%;
}

.inspire3 p {
  color: #fff;
  font-family: Kanit;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.78px;
  padding: 0px 50px 65px 0px;
}

.inspireFour {
  padding: 0px !important;
}

.inspire4 {
  background: #4c004e;
  padding: 20px 0px 0px 0px;
  height: 420px;
}

.inspire4 img {
  width: 100%;
}

.inspire4 p {
  color: #fff;
  font-family: Kanit;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.78px;
  padding: 0px 50px 95px 0px;
}

.inspireFour {
  padding: 0px !important;
}

.imspire4 {
  border-radius: 0px 15px 15px 0px;
  background: #0075d9;
  height: 420px;
}

.alumni-button {
  text-align: center;
  margin: 30px 0px 0px 0px;
  padding-bottom: 20px;
}

.alumni-button button {
  border: none;
  border-radius: 7px;
  background: linear-gradient(93deg, #f17218 1.33%, #f19e49 105.93%);
  color: #fff;
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 25px 10px 25px;
}

.enroll-process {
  text-align: center;
  /* padding-bottom: 50px; */
}

.enroll-process p {
  color: #494949;
  font-family: Judson;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 110px;
  /* 134.783% */
}

.enroll-process span {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  font-family: Judson;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px;
  /* 134.783% */
  background: linear-gradient(91deg, #e9aa00 45.88%, #f27017 100.92%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.enroll-process1 {
  margin-top: 10px;
  border-radius: 15px;
  border: 1px solid #fdb813;
  background: #fff2e5;
  padding: 50px 10px 0px 10px;
  height: 252px;
}

.enroll-process1 img {
  padding: 0px 90px 10px;
}

.enroll-process1Detail {
  display: flex;
}

.enroll-process1Detail h6 {
  color: #fdb813;
  font-family: Kanit;
  font-size: 64px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 1.92px;
  padding: 0px 10px 0px 0px;
}

.enroll-process1Detail span {
  color: #494949;
  font-family: Kanit;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.66px;
}

.enroll-process1Detail p {
  color: #3a3a44;
  font-family: Kanit;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.home-sec6 {
  margin-top: 100px;
  background: #faf7f2;
}

.instructor {
  text-align: center;
  padding: 30px 0px 20px 0px;
}

.instructor p {
  color: #494949;
  font-family: Judson;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px;
  /* 134.783% */
}

.instructor span {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  font-family: Judson;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px;
  /* 134.783% */
  background: linear-gradient(91deg, #e9aa00 45.88%, #f27017 100.92%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.instructor1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 0px 10px 0px;
  margin: 0px 0px 30px 0px;
}

.instructor1 img {
  background-color: black;
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.instructor1 h6 {
  margin-top: 10px;
}

.instructor1-detail {
  display: flex;
}

.instructor1-detail img {
  margin-right: 10px;
  border-radius: 0px;
  background: #faf7f2;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.instructor1-detail p {
  color: #494949;
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
}

.instructor1-detail span {
  color: #9c9c9c;
  font-family: Kanit;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.42px;
}

.instructor1 p {
  color: #575757;
  font-family: Kanit;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.42px;
}

.instructor1 span {
  color: #9c9c9c;
  font-family: Kanit;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.42px;
}

.instructor-aboutInfo h4 {
  color: #494949;
  /* font-family: Judson; */
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  /* 134.783% */
}

.instructor-aboutInfo p {
  color: #5e5e5e;
  font-family: Kanit;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.Achivement {
  display: flex;
  align-items: center;
}

.Achivement p {
  margin-left: 20px;
  color: #494949;
  font-family: Kanit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.66px;
}

.Achivement span {
  color: #5e5e5e;
  font-family: Kanit;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.mentor-button {
  border: none;
  border-radius: 7px;
  color: #fff;
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: linear-gradient(93deg, #f17218 1.33%, #f19e49 105.93%);
  padding: 10px 10px 10px 10px;
  margin-top: 20px;
}

.home-sec7 {
  background: white;
  padding: 40px 0px 40px 0px;
}

.section-seven {
  background-color: #0b2071;
  padding: 100px 60px 40px 40px;
}

.Recognized-Award h6 {
  color: #fff;
  font-family: Judson;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Recognized-Award span {
  color: #fff;
  font-family: Kanit;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.96px;
}

.Recognized-Award p {
  color: #fff;
  font-family: Kanit;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.home-sec8 {
  background: #faf7f2;
  padding: 30px;
}

.testimonial-detail h5 {
  /* text-align: right; */
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  font-family: Judson;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px;
  /* 134.783% */
  background: linear-gradient(91deg, #e9aa00 45.88%, #f27017 100.92%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.testimonial-detail P {
  color: #494949;
  font-family: Judson;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  /* 134.783% */
}

.testimonial-detail h6 {
  border-radius: 30px 30px 30px 0px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  color: #5e5e5e;
  font-family: Kanit;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 10px 0px 10px 20px;
}

.developer-detail {
  display: flex;
}

.developerDetail-owl .developer-detail img {
  width: 95px !important;
  height: 95px !important;
}

.developer-info {
  margin-left: 20px;
}

.owl-stage-outer {
  margin-top: 20px;
}

.owl-dots {
  margin-top: -30px !important;
}

.developer-info span {
  margin-left: 10px;
  color: #6c6c6c;
  font-family: Kanit;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 31px;
  /* 155% */
  letter-spacing: 0.2px;
}

.developer-info h6 {
  color: #6c6c6c;
  font-family: Kanit;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.2px;
}

.developer-rating {
  display: flex;
}

.developer-rating p {
  color: #2d2e2d;
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
}

.developerDetail-owl .developer-rating img {
  margin-left: 20px;
  width: 64px !important;
  height: 11.368px !important;
  margin-top: 7px;
  flex-shrink: 0;
}

.developer-info h4 {
  color: #6c6c6c;
  font-family: Kanit;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.2px;
}

.home-sec9 {
  background: url(../images/register.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 650px; */
  padding: 50px;
  /* width: ; */
}

.Register-offer p {
  color: #fff;
  font-family: Judson;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px;
  /* 134.783% */
}

.Register-offer h6 {
  color: #fff;
  font-family: Judson;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px;
  /* 124% */
}

.Register-sec {
  /* margin: 126px auto 20px auto; */
  background-color: white;
  border-radius: 10px;
  /* Common styles for different screens */
}

.Register-sec h4 {
  border-radius: 10px 10px 0px 0px;
  background: #fff;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
  padding: 15px 20px;
  color: #494949;
  font-family: Judson;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.form-div {
  padding: 30px 20px;
  height: 368px;
}

.form-div h4 {
  text-align: center;
  font-weight: 500;
  font-size: 32px;
  color: #112b4f;
}

.form-div label {
  font-weight: 500;
  font-size: 16px;
  color: #676767;
}

.form-div input[type="text"].name {
  border: none;
  border-bottom: 1px solid #848484;
  border-radius: 0;
  padding: 0;
}

.form-div input {
  border: none;
  border-bottom: 1px solid #848484;
  border-radius: 0;
  padding: 10px;
}

.form-div input:focus {
  outline: 0;
  box-shadow: none;
}

.form-div .password::placeholder,
.form-div .email::placeholder,
.form-div .Name::placeholder {
  color: #b3b3b3;
  font-family: Kanit;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.pass--box {
  position: relative;

}

.Register-div {
  text-align: center;
  margin-top: 40px;
}

.Register-btnadm {
  padding: 15px;
  background-color: orange;
  border-radius: 10px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.54px;
  width: 90%;
  border: none;
}

/* .logbtnadm:hover {
    background: #013868;
  } */
.home-sec10 {
  padding: 40px;
  margin: 10px 0px 50px 0px;
}

.award2 img {
  height: auto;
  width: 100%;
}

.offer-img2 img {
  width: 100%;
  height: auto;
}

.home-mainImg img {
  width: 100%;
  height: auto;
}

.specialOffer-detail {
  text-align: center;
}

.specialOffer-detail p {
  color: #494949;
  font-family: Judson;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px;
  /* 134.783% */
}

.specialOffer-detail span {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background: linear-gradient(91deg, #e9aa00 45.88%, #f27017 100.92%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.specialOffer-detail h6 {
  color: #5e5e5e;
  text-align: center;
  font-family: Kanit;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.specialOffer-detail button {
  border: none;
  color: #fff;
  font-family: Kanit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 7px;
  background: linear-gradient(93deg, #f17218 1.33%, #f19e49 105.93%);
  box-shadow: 0px 0px 15px 0px rgba(248, 152, 29, 0.5);
  padding: 5px 50px 5px 50px;
}

.home-sec11 {
  background: #faf7f2;
  padding: 40px;
}

.question {
  text-align: center;
}

.question p {
  color: #25324a;
  text-align: center;
  font-family: Judson;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
  /* 135% */
}

.question span {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background: linear-gradient(91deg, #e9aa00 45.88%, #f27017 100.92%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.myaccrditem {
  margin-bottom: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
}

.myaccrdbtn {
  padding: 10px 25px;
}

.myaccrdbtn:focus {
  box-shadow: 0 0 0 1px rgba(13, 110, 253, 0.25);
}

.myaccrdbtn h6 {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0;
}

.myaccrdbtn:not(.collapsed) {
  background-color: #f1f4f9;
}

.acrdbdy p {
  color: #848383;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.2px;
  margin-bottom: 0;
}

.myaccrdbtn::after {
  width: 1.25rem;
  height: 1.25rem;
  background-image: url(../images/max.png);
}

.myaccrdbtn:not(.collapsed)::after {
  background-image: url(../images/min.png);
  transform: rotate(-180deg);
}

.home-sec12 {
  background: #1a4674;
  padding: 40px;
}

.youTube-caption h6 {
  color: #fff;
  font-family: Kanit;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.youTube-caption p {
  color: #fff;
  font-family: Kanit;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  /* 140% */
  letter-spacing: 0.2px;
}

/* //// anew accordans  */

.sildercenter {
  overflow: hidden;
  /* background-color: #000c4e; */
  /* margin: 100px auto; */
  /* box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.35); */
  /* -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.35); */
  /* -moz-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.35); */
}

/*A small hack to prevent flickering on some browsers*/
.sildercenter ul {
  width: 2000px;
  /* margin-left: 20px; */
  /*This will give ample space to the last item to move
	instead of falling down/flickering during hovers.*/
}

.sildercenter li {
  position: relative;
  display: block;
  width: 200px;
  float: left;

  /* border-left: 1px solid #888; */

  /* box-shadow: 0 0 25px 10px rgba(0, 0, 0, 0.5); */
  /* -webkit-box-shadow: 0 0 25px 10px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0 0 25px 10px rgba(0, 0, 0, 0.5); */

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*Reduce with of un-hovered elements*/
.sildercenter ul:hover li {
  width: 40px;
}

/*Lets apply hover effects now*/
/*The LI hover style should override the UL hover style*/
.sildercenter ul li:hover {
  width: 640px;
}

.sildercenter li img {
  display: block;
}

/*Image title styles*/
.sliderhome {
  color: #fff;
  border-radius: 15px 0px 0px 15px;
  background: #6715a8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* margin: 20px; */
  overflow: hidden;
}

.sliderhome-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.profile-icon {
  margin-right: 15px;
}

.profile-icon img {
  width: 50px;
  /* Adjust the size as needed */
  border-radius: 50%;
  /* For a circular profile icon */
}

.header-text h2 {
  margin: 0;
  font-size: 24px;
  color: #fff;
}

.header-text p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #fff;
}

.sliderhome-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.sliderhome a {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 20px;
  font-size: 16px;
}

/* // extra css  */

.sliderhome-content {
  /* padding: 20px; */
  height: 500px;
}

.pt-24 {
  padding-top: 24px !important;
}

h4.heading.zen-heading-4.pl-24.mt-12 {
  /* max-width: 150px;
  padding-right: 24px; */
  margin-left: 20px;
}

.description {
  margin-left: 20px;
  margin-top: 20px;
  min-height: 120px;
}

.zen-big-body {
  font: 400 18px/32px Mulish, Roboto, Helvetica Neue, sans-serif;
}

.w-84 {
  width: 84px !important;
  min-width: 84px !important;
  max-width: 84px !important;
}

@media (max-width: 769px) {
  .inner-content.pl-24 {
    width: 336px !important;
    padding-left: 32px !important;
  }
}

.flex-container {
  display: flex;
}

.sliderend {
  color: #fff;
  border-radius: 15px 0px 0px 15px;
  border-radius: 0px 15px 15px 0px;
  background: #0075d9;
  margin: 20px;
  overflow: hidden;
}

.sliderend-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.sliderend-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.sliderend a {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 20px;
  font-size: 16px;
}

/* // extra css  */

.sliderend-content {
  /* padding: 20px; */
  height: 500px;
}

.owl-item {
  width: 128.906px;
  margin-right: 10px;
  /* background: powderblue; */
}

.CircularProgressbar {
  height: 100px;
  margin-left: 30px;
  margin-top: 30px;
  display: flex;
}

h4.headers {
  margin-top: 30px;
  margin-left: 30px;
  color: #fff;
}

.offersshift {
  margin-left: 10px;
  font-size: 35px;
}

.testimonalimg img {
  width: 100%;
  height: auto;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.teachers img {
  height: auto;
  width: 100%;
}

.placed-companies {
  margin-top: 10px;
}

.teachers img {
  margin-top: 20px;
}

.home-sec5 {
  margin-top: 72px;
}

.compromise {
  margin-left: 28px;
}

.bottomsection {
  display: flex;
  justify-content: space-between;
}

/* // about as css  */
.About-sec1 {
  background-color: rgb(10, 10, 130);
  padding: 50px;
}

.About-BasicDetail p {
  color: #fff;
  font-family: Judson;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
  /* 126.087% */
}

.About-BasicDetail span {
  background: linear-gradient(95deg, #fdb813 24.97%, #f37321 63.62%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.About-BasicDetail h6 {
  color: #fff;
  font-family: Kanit;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.About-img {
  display: flex;
  align-items: center;
}

/* .About-img img{
  padding: 0px 10px 20px 50px ;

} */
.About-img1 {
  display: flex;
  flex-direction: column;
}

.history-sec {
  background: #faf7f2;
  padding: 50px;
}

.our-history {
  text-align: center;
}

.our-history p {
  margin-top: 20px;
  color: #494949;
  font-family: Judson;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px;
  /* 134.783% */
}

.our-history span {
  margin-left: 20px;
  font-size: 40px;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background: linear-gradient(91deg, #e9aa00 45.88%, #f27017 100.92%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.history-detail span {
  color: #5e5e5e;
  font-family: Kanit;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.history-detail p {
  color: #5e5e5e;
  font-family: Kanit;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-top: 10px;
}

.history-img {
  display: flex;
}

/* .history-img img {
  padding: 0px 10px 20px 30px ;
} */

.mission-sec {
  background: #fff;
  padding: 50px;
}

.our-mission {
  text-align: center;
}

.our-mission p {
  color: #494949;
  font-family: Judson;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px;
  /* 134.783% */
}

.our-mission span {
  font-size: 40px;
  margin-left: 20px;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background: linear-gradient(91deg, #e9aa00 45.88%, #f27017 100.92%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mission-detail p {
  color: #5e5e5e;
  font-family: Kanit;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-top: 50px;
}

.journey-sec {
  background: #faf7f2;
  padding: 50px;
}

.our-journey {
  text-align: center;
  margin-bottom: 128px;
}

.our-journey p {
  color: #494949;
  font-family: Judson;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px;
  /* 134.783% */
}

.our-journey span {
  margin-left: 20px;
  font-size: 40px;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background: linear-gradient(91deg, #e9aa00 45.88%, #f27017 100.92%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.journey1 {
  position: absolute;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  top: -80px;
  left: 4;
  z-index: 20;
}

.journey1 img {
  margin-top: 16px;
  margin-left: 24px;
  height: auto;
  width: 134px;
}

.journey-detail p {
  margin-left: 30px;
  margin-top: 20px;
  color: #494949;
  font-family: Kanit;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.66px;
}

.Ellipse1 {
  position: absolute;
  right: 38px;
  bottom: 70px;
}

.about1 img {
  border-radius: 89px;
  width: 237px;
  height: 155px;
  flex-shrink: 0;
  margin-top: 20px;
}

.about2 img {
  border-radius: 0px 102px 0px 0px;
  width: 228px;
  height: 177px;
}

.about3 img {
  border-radius: 0px 0px 0px 102px;
  width: 228px;
  height: 177px;
  margin-top: 20px;
}

.img3about1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img2about1 img {
  margin-top: 20px;
  border-radius: 0px 102px 0px 0px;
}

.img1about1 img {
  margin-top: 20px;
  border-radius: 89px;
}

.img3about1 img {
  border-radius: 22px;
  font-weight: 200px;
}

.journey-detail {
  position: relative;
  /* Set the parent to relative positioning */
}

/* styles.css */

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}

/* Apply the animation to the image class */
.bouncing-image {
  animation: bounce 2s infinite;
}

.ellipse-image {
  position: absolute;
  right: -50px;
  /* Change this value to adjust the distance from the left */
  top: 50%;
  /* Adjust as needed */
  transform: translateY(-50%);
  width: 80px;
  /* Adjust the width if needed */
  /* Add any other styles you require */
}

.ellipse-image2 {
  position: absolute;
  right: -55px;
  top: 26%;
  width: 80px;
}

.connectdotodd {
  position: relative;
  z-index: 11;
}

.connectdotodd::after {
  content: url(../images/Ellipse1.png);
  position: absolute;
  top: 80px;
  right: -35px;
  z-index: -1;
}

.connectdoteven {
  position: relative;
  z-index: 11;
}

.connectdoteven::after {
  content: url(../images/Ellipse2.png);
  position: absolute;
  top: 20px;
  right: -40px;
  z-index: -1;
}

.error {
  border-color: red;
}

.error-message {
  color: red;
}



.quizzesData {
  margin: 6px 25px;

}

.dataquizCenter {
  display: flex;
  width: 93% !important;
  border-top: 0.5px solid #EDEDED;
  border-bottom: 0.5px solid #EDEDED;
  margin-left: 0px !important;
  justify-content: space-between;
  margin: 13px 16px;
}

.dataquizCenter img {
  width: 19px;
  margin-right: 6px;
  height: 19px;
}

.dataquizCenter span {
  font-family: Satoshi;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.quizzesData .translateIcon img {
  width: 16px;
  hyphens: 16px;
}

.quizzesData .translateIcon span {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  margin-left: 6px;
  text-align: left;
  color: #007AFF;
}

.quizzBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quizzBox button {
  background: linear-gradient(0deg, #EE643C 58.97%, #F4933D 125.97%);
  font-family: Kanit;
  font-size: 13.75px;
  font-weight: 400;
  line-height: 20.55px;
  letter-spacing: 0.02em;
  color: white;
  border: 1px solid;
  border-radius: 8px;
  padding: 8px 10px;
  min-width: 80px;
  text-align: center;
  margin: 0px 4px;
}

.upcomingCard {
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 0px 20px 0px #E8E8E8B2;
  border-radius: 16px;
  padding: 10px;
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: space-between;
}

.upcomingCard img {
  width: 35px;
  height: 35px;
}

.upcomingCard h6 {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.91px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #494949;
}

.upcomingCard span {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.93px;
  letter-spacing: 0.03em;
  text-align: left;

}

.upcomingCard svg {
  margin-right: 10px;
}

.installmentDate {
  background: linear-gradient(90.97deg, #F47720 0.17%, #FF6565 98.75%);
  border: 2px dotted #FFFFFF;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #E8E8E8B2;

}

.installmentDate h6 {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  line-height: 16px;
  text-align: left;
  color: #FFFFFF;
}

.achievement {
  background: #FFFFFF;
  margin-top: 20px;
  box-shadow: 0px 0px 20px 0px #E8E8E8B2;
  padding: 10px;
  border-radius: 20px;
}

.achievement h6 {
  font-family: Judson;
  font-size: 22px;
  font-weight: 700;
  line-height: 25.59px;
  text-align: left;
  color: #494949;
}

.achievement svg {
  width: 90px;
}

.achievement h5 {
  font-family: Kanit;
  font-size: 15px;
  margin-left: 6%;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
  display: flex;
  align-items: center;
  color: #5E5E5E;
}

.achievement h5 span {
  font-family: Judson;
  font-size: 42px;
  font-weight: 700;
  line-height: 48.85px;
  text-align: left;
  margin-left: 10px;
  color: #1A4674;
}


.upcomingevent {
  background: #FFFFFF;
  margin-top: 20px;
  box-shadow: 0px 0px 20px 0px #E8E8E8B2;
  padding: 10px;
  border-radius: 20px;
}

.performceChart {
  
  width: 100%;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 0px #E8E8E8B2, 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.performceChart h3 {
  font-family: Judson;
  font-size: 20px;
  margin: 0px;
  font-weight: 700;
  line-height: 25.59px;
  margin: 0px !important;
  text-align: left;
  color: #494949;
}

.performceChart select {
  border: none !important;
}

.performceChart select::selection {
  border: none !important;
}

.upcomingevent h6 {
  font-family: Judson;
  font-size: 22px;
  font-weight: 700;
  margin: 0px;
  line-height: 25.59px;
  text-align: left;
  color: #494949;
}

.boxupcomingevent {
  border: 1px solid #EBEBEB;
  color: #FFFFFF;
  padding: 6px;
  box-shadow: 0px 0px 20px 0px #E8E8E8B2;
  border-radius: 16px;
}

.titleShow {
  margin-left: 8px;
  width: 40%;
}

.titleShow h6 {
  font-family: Kanit;
  font-size: 10px;
  margin: 0px !important;
  font-weight: 400;
  margin: 0px;
  line-height: 13px;
  text-align: left;
  color: #5E5E5E;
}

.titleShow h5 {
  font-family: Kanit;
  font-size: 8px;
  font-weight: 300;
  line-height: 11.96px;
  margin: 0px !important;
  letter-spacing: 0.03em;
  text-align: left;
  color: #95A5A6;
}

.upcomingevent span {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 300;
  margin: 0px !important;
  line-height: 20.93px;
  text-align: left;
  color: #F37321;

}

.upcomingevent svg {
  width: 90px;
}

.calanderShowBox span {
  font-family: Kanit;
  font-size: 8px;
  font-weight: 300;
  line-height: 11.96px;
  margin: 0px !important;
  width: 100%;
  letter-spacing: 0.03em;
  text-align: left;
  color: #95A5A6;

}

.calanderShowBox svg {
  width: 8px;
  height: 8px;
  margin-right: 6px;
}

.locationShowBox span {
  font-family: Kanit;
  font-size: 8px;
  font-weight: 300;
  line-height: 11.96px;
  margin: 0px !important;
  width: 100%;
  letter-spacing: 0.03em;
  text-align: left;
  color: #95A5A6;

}

.locationShowBox svg {
  width: 8px;
  height: 8px;
  margin-right: 6px;
}

.manageAssignment-top button {
  border: 1px solid;
  width: 200px;
  height: 40px;
  border-radius: 20px;
  font-family: Kanit;
  font-size: 16px;
  font-weight: 300;
  line-height: 29.9px;
  margin: 5px;
  border: 1px 0px 0px 0px;
  border-image-source: linear-gradient(180deg, #FDB813 0%, #F37321 100%);
}

.manageAssignment-top .activeClassAssignmemnt {
  background: linear-gradient(91.99deg, #FDB813 3.73%, #F37321 96.52%) !important;
  color: #FFFFFF;
}

.create-assignment {
  font-family: Satoshi;
  font-size: 13.75px;
  background: linear-gradient(0deg, #EE643C 58.97%, #F4933D 125.97%);
  font-weight: 500;
  line-height: 18.56px;
  border-radius: 5px;
  border: none;
  padding: 5px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #FFFFFF;
}


.showTableAssgiment table {
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 0px #E8E8E8B2;
  padding: 10px;
  margin-top: 20px;
  text-align: center;
  width: 100%;
  border-radius: 20px;
}

.showTableAssgiment table tr th {
  font-family: Kanit;
  font-size: 16px;
  padding: 10px;
  margin: 10px 0px;
  font-weight: 400;
  line-height: 26.91px;
  letter-spacing: 0.03em;
  text-align: center;
  color: #494949;
}

.showTableAssgiment table td {
  font-family: Kanit;
  font-size: 14px;
  border-top: 1px solid #E6E6E6;
  font-weight: 200;
  margin: 10px 0px;
  padding: 6px 0px;
  line-height: 26.91px;
  letter-spacing: 0.03em;
  text-align: center;
  color: #494949;
}

/* chat css  */
.chatPepple {
  width: 100%;
  border: 1px solid rgba(217, 217, 217, 1);
  background: rgba(255, 255, 255, 1);
  border-radius: 14px;
  padding: 0px 10px;
  padding-top: 10px !important;
  border: 1px 0px 0px 0px;
}

.doubtClearing .container-fluid {
  padding: 10px;
}

.chatPepple h6 {
  font-family: Judson;
  font-size: 16px;
  font-weight: 700;
  line-height: 30.24px;
  text-align: left;
  color: rgba(73, 73, 73, 1);

}

.chatPepple .searchBox {
  position: relative;
  background: rgba(242, 242, 242, 0.7);
  border-radius: 6px;
}

.chatPepple .searchBox input {
  border-radius: 6px !important;
}

.chatPepple .searchBox input:focus {
  border: 1px solid !important;
  box-shadow: none !important;
}

.chatPepple .searchBox input::placeholder {
  font-family: Kanit;
  font-size: 13px;
  font-weight: 300;
  line-height: 23.92px;
  letter-spacing: 0.03em;
  text-align: left;

  color: rgba(185, 185, 185, 1);

}

.chatPepple .searchBox i {
  position: absolute;
  z-index: 9999;
  right: 12px;
  top: 11px;
}

.chatPepple .chatButtonTop {
  display: flex;
  justify-content: center;
}

.chatPepple .chatButtonTop button {
  border: 1px solid;
  width: 120px;
  margin: 0px 6px;
  height: 37px;
  margin-bottom: 10px;
  border-radius: 6px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border-image-source: linear-gradient(180deg, #F37321 0%, #F8981D 100%);

}

.activechatTop {
  background: linear-gradient(95.8deg, #FDB813 0%, #F37321 96.97%);
  color: white;
}

.chatShowName {
  display: flex;
  margin: 20px 0px;
  align-items: center;
}

.chatShowName img {
  width: 54px;
  height: 54px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 50%;
}

.showNameProfile {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.showNameProfile h5 {
  margin: 0px;
  font-family: Kanit;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: rgba(73, 73, 73, 1);
  text-align: left;

}

.showNameProfile h6 {
  margin: 0px;
  font-family: Kanit;
  font-size: 10px;
  font-weight: 300;
  line-height: 14.95px;
  letter-spacing: 0.03em;
  text-align: left;
  color: rgba(149, 165, 166, 1);
}

.showTimeProfile span {
  color: rgba(255, 255, 255, 1);
  width: 20px;
  height: 20px;
  font-size: 10px;
  padding: 6px;
  border-radius: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  background: rgba(243, 115, 33, 1);
}

.chatPersonTop {
  border: 1px solid rgba(217, 217, 217, 1);
  background: rgba(255, 255, 255, 1);
  height: 80px;
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.chatPersonTop img {
  border-radius: 50%;
  width: 54px;
  margin-left: 20px;
  height: 54px;
}

.profileShow {
  position: relative;
}

.profileShow .proffileOnline {
  width: 10px;
  position: absolute;
  top: 3px;
  right: 0;
  border-radius: 50%;
  outline: 2px solid white;
  height: 10px;
  background: rgba(18, 169, 24, 1);
}

.profileNameContent {
  margin-left: 20px;
}

.profileNameContent h6 {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  letter-spacing: 0.03em;
  text-align: left;
  color: rgba(73, 73, 73, 1);
}

.profileNameContent span {
  font-family: Kanit;
  font-size: 12px;
  font-weight: 300;
  line-height: 17.94px;
  letter-spacing: 0.03em;
  text-align: left;
  color: rgba(149, 165, 166, 1);

}

.callIcon {
  display: flex;
}

.callIcon button {
  width: 34px;
  border: none;
  display: flex;
  margin: 0px 5px;
  align-items: center;
  justify-content: center;
  height: 34px;
  border-radius: 6px;
}

.callIcon .audioCallIcon {
  background: rgba(234, 235, 253, 1);
}

.callIcon .videoCallIcon {
  background: rgba(254, 227, 217, 1);
}

.menuicon {
  background-color: transparent;
  border: none;
  margin-left: 10px;
}



.jobNotificationCard {
  box-shadow: 0px 0px 43.5px 0px #56585840;
  background: #FFFFFF;
  border-radius: 16px;
  padding-bottom: 15px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jobLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jobLogo svg {
  width: 140px;
  height: 140px;
}

.jobNotificationCard .titleJob h5 {
  color: #1D0D2F;
  font-family: Kanit;
  font-size: 22px;
  font-weight: 500;
  line-height: 32.89px;
  letter-spacing: 0.03em;
  text-align: left;

}

.jobNotificationCard button {
  background: linear-gradient(0deg, #EE643C 58.97%, #F4933D 125.97%);
  color: white;
  padding: 3px 23px;
  border-radius: 4px;
  border: 1px solid;
  font-size: 15px;
}

.titleExam {
  box-shadow: 0px 0px 40px 0px #38393940;
  background: #FFFFFF;
  padding: 13px 16px;
  margin: 10px 0px;
  border-radius: 12px;
}

.titleExam input {
  width: 20px !important;
  height: 20px !important;
}

.titleExam input:checked {
  border: 1px solid #2C9607;
  background-color: #2C9607;

}

.titleExam label {
  font-size: 15px;
  margin-left: 4px;
  font-weight: 500;
}

.status {
  box-shadow: 0px 0px 40px 0px #38393940;
  background: #FFFFFF;
  border-radius: 12px;
  margin: 20px 0px;
  padding: 10px 13px;
}

.status h6 {
  color: #1D0D2F;
  margin: 0px 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #BDB1FF;
}

.status .form-check {
  margin: 8px;
}

.status input {
  width: 20px !important;
  height: 20px !important;
}

.status input:checked {
  border: 1px solid #2C9607;
  background-color: #2C9607;

}

.status label {
  font-size: 15px;
  margin-left: 4px;
  font-weight: 500;
}

.exampar {
  box-shadow: 0px 0px 40px 0px #38393940;
  background: #FFFFFF;
  height: 100%;
  padding: 20px;
  border-radius: 12px;
}

.centerpar {
  box-shadow: 0px 0px 40px 0px #38393940;
  border-radius: 12px;
  padding: 12px;
  background: #FFFFFF;
}

.centerpar p {
  margin: 0px;
}



.mockInterview h6 {
  color: #1D0D2F;
  font-family: Kanit;
  font-size: 20px;
  font-weight: 500;
  line-height: 29.9px;
  margin: 10px;
  letter-spacing: 0.03em;
  text-align: left;

}

.interviewTypeBox {
  box-shadow: 0px 0px 40px 0px #38393940;
  padding: 6px 10px;
  margin: 10px 0px;
  border-radius: 12px;
  background: #FFFFFF;
}

.interviewType label {
  color: #1D0D2F;
  font-family: Kanit;
  font-size: 14px;
  font-weight: 500;
  line-height: 29.9px;
  letter-spacing: 0.03em;
  text-align: left;
}

.interviewTypeTable {
  width: 100%;
  box-shadow: 0px 0px 40px 0px #38393940;
  background: #FFFFFF;
  border-radius: 12px;
  padding: 6px 10px;
}

.interviewTypeTable th {
  color: #1D0D2F;
  font-family: Kanit;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  padding: 6px 10px;
  line-height: 29.9px;
  text-align: left;
}

.interviewTypeTable td {
  border-top: 1px solid #CBCACA;
  padding: 10px 12px;
}

.interviewTypeTable .completedBox {
  background: #adf0af;
  font-family: Kanit;
  font-size: 16px;
  font-weight: 400;
  color: #00B707;
  border: 1px solid #00B707;
  text-align: center !important;
  line-height: 23.92px;
  border-radius: 10px;
  letter-spacing: 0.03em;
  text-align: left;
}

.interviewTypeTable .pendingBox {
  background: #FFB7B9;
  font-family: Kanit;
  font-size: 16px;
  font-weight: 400;
  color: #EC3237;
  border: 1px solid #FFB7B9;
  text-align: center !important;
  line-height: 23.92px;
  border-radius: 10px;
  letter-spacing: 0.03em;
  text-align: left;
}

.interviewTypeTable .resceduleBox {
  background: #FBB284;
  font-family: Kanit;
  font-size: 16px;
  font-weight: 400;
  color: #F37321;
  border: 1px solid #FBB284;
  text-align: center !important;
  line-height: 23.92px;
  border-radius: 10px;
  letter-spacing: 0.03em;
  text-align: left;
}



.myClasses .buttonShow {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.myClasses button {
  border: 1px solid #FDB813;
  /* border-image-source: linear-gradient(180deg, #FDB813 0%, #F37321 100%); */
  width: 250px;
  cursor: pointer;
  background-color: transparent;
  height: 40px;
  margin-bottom: 20px;
  border-radius: 30px;
  /* opacity: 0px; */
}

.myClasses .activeClasses {
  color: white;
  cursor: pointer;
  background: linear-gradient(92.02deg, #FDB813 2.12%, #F37321 96.94%);
}

.liveClasses {
  margin-top: 20px;
}

.chatLiveClasses {
  backdrop-filter: blur(33.33333206176758px);
  background: rgba(248, 248, 248, 1);
  border: 0.83px solid rgba(216, 216, 216, 1);
  padding: 10px;
  border-radius: 15px;
  margin: 11px;
}

.chatLiveClasses .showDescription {
  display: flex;
  margin: 16px 0px;
  align-items: center;
}

.chatLiveClasses input::placeholder {
  color: rgba(138, 138, 138, 0.6) !important;
  background-color: transparent;
}

.chatLiveClasses input {
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 10px;
}

.chatliveBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatliveBottom .buttonIcon {
  display: flex !important;
  align-items: center;
}

.chatliveBottom .buttonIcon svg {
  margin: 0px 5px !important;
}

.buttonShowBottom {
  display: flex;
  justify-content: flex-end;
}

.buttonShowBottom button {
  background: rgba(243, 115, 33, 1);
  color: white;
  width: 130px;
  border-radius: 6px;
  height: 40px;
}


.videoTitle {}

.videoTitle .titleHedVideo h4 {
  color: rgba(0, 122, 255, 1);
}

.liveClasses h5 {
  color: rgba(0, 122, 255, 1) !important;
}

.topicClassesBox {
  background: rgba(255, 255, 255, 1);
  padding: 10px;
  margin: 20px 0px;
  box-shadow: 0px 0px 20px 0px rgba(232, 232, 232, 0.7);
  border-radius: 16px;
}

.topicClassesBox .topicClassesBoxHead {
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  align-items: center;
  border-bottom: 1px solid rgba(230, 230, 230, 1)
}

.topicClassesBox h4 {
  color: rgba(196, 196, 196, 1);
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 3px
}

.leaveAttendanceBox {
  border-radius: 16px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 20px 0px rgba(232, 232, 232, 0.7);
  padding: 10px;
  margin: 10px;
}

.leaveAttendanceBox table {
  width: 100%;
}
.leaveAttendanceBox table tr{
  border: 1px solid rgba(230, 230, 230, 1)
}
.leaveAttendanceBox table th {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.91px;
  padding: 6px;
  letter-spacing: 0.03em;
  text-align: center;
  color: rgba(73, 73, 73, 1);
}
.leaveAttendanceBox table .present{
  color: rgba(44, 150, 7, 1);
}
.leaveAttendanceBox table .absent{
  color: rgba(236, 50, 55, 1);
}
.leaveAttendanceBox table td {
  font-family: Kanit;
  font-size: 20px;
  font-weight: 300;
  line-height: 26.91px;
  text-align: center;
  letter-spacing: 0.03em;
  color: rgba(73, 73, 73, 1);
}
.leaveAttendanceBox table .presentbtn, .leaveAttendanceBox table .absentbtn{
  font-family: Kanit;
  font-size: 15px;
  font-weight: 300;
  border: none;
  line-height: 20.93px;
  margin: 6px 0px;
  border-radius: 2px;
  letter-spacing: 0.03em;
  padding: 4px;
  text-align: center;
  width: 80px;
  margin-right:10px;
  background: rgba(44, 150, 7, 1);
  color: rgba(255, 255, 255, 1);
}

.leaveAttendanceBox table .absentbtn{
  margin-right: 0;
  background: rgba(236, 50, 55, 1);
}

.topicClassesBox .topicClassesBoxHead h6 {
  color: rgba(196, 196, 196, 1);
  font-size: 16px;
}

.topicClassesBoxHead .topicClassesBoxHeadRight {
  display: flex;
  align-items: center;
}

.topicClassesBoxHead .topicClassesBoxHeadRight span {
  color: rgba(196, 196, 196, 1);
  margin-right: 10px;
  font-size: 10px !important;
}

.topicClassesBoxHead .topicClassesBoxHeadRight button {
  background: rgba(255, 255, 255, 1);
  border: 0.82px solid rgba(241, 241, 245, 1);
  border-radius: 6px;
  margin: 0px !important;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatLiveClasses input:focus {
  border: none;
  outline: none;
}

.chatLiveClasses h6 {
  font-size: 12px;
  margin-bottom: 0px !important;
  margin-left: 10px;
  margin-right: 6px;
}

.chatLiveClasses span {
  font-size: 12px !important;
}

.liveClasses iframe {
  border-radius: 14px;
  background: linear-gradient(360deg, #25324A 0%, rgba(37, 50, 74, 0) 100%);

}

.videoTitle {
  margin-top: 10px;
  margin-left: 20px;
  width: 100%;
  display: flex;
}

.videoTitle h4 {
  font-family: Sora;
  font-size: 19.65px;
  font-weight: 600;
  line-height: 24.75px;
  color: rgba(31, 32, 41, 1);
  text-align: left;

}

.videoTitle span {
  color: rgba(38, 50, 56, 1);
  font-size: 10px;
}

.titleHedVideo {
  display: flex;
  width: 95%;
  justify-content: space-between;
}

.liveClasses h5 {
  margin-left: 20px;
  font-family: Plus Jakarta Sans;
  font-size: 14.73px;
  font-weight: 600;
  line-height: 20.46px;
  text-align: left;
  color: rgba(31, 32, 41, 1);

}

.liveClasses p {
  font-family: Kanit;
  font-size: 17px;
  font-weight: 300;
  margin-left: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-align: left;
  margin-top: 10px;
  color: rgba(132, 131, 131, 1);

}

.menuicon svg {
  font-size: 13px;
  width: 25px;
  height: 25px;
}

.chatBottomSheet {
  width: 100%;
}

.openmenuShow {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  border: 1px solid transparent;
  align-items: center;
  position: absolute;
  bottom: 54px;
  z-index: 99999999;
  width: 42px;
}

.openmenuShow button {
  border: none;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  margin: 6px 0px;
  justify-content: center;
  border-radius: 30px;
  background-color: transparent;
}

.openmenuShow .copyPageIcon {
  background: rgba(216, 255, 217, 1);

}

.openmenuShow .linkCopyIcon {
  background: rgba(234, 235, 253, 1);

}

.openmenuShow .micePhoneIcon {
  background: rgba(254, 227, 217, 1);
}


.menubottom {
  background: rgba(243, 243, 243, 1);
  border: none;
  margin: 10px;
  border-radius: 50%;
  padding: 2px;
  backdrop-filter: blur(2px)
}

.menubottom svg {
  color: rgba(86, 86, 86, 1);
  padding: 3px 4px;
  font-size: 13px;
  width: 25px;
  height: 25px;
}

.chatBottomSheet .input-group {
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
}

.chatBottomSheet span {
  background-color: transparent;
  border: none;
}

.chatBottomSheet input {
  border: none;
}

.chatBottomSheet input:focus {
  box-shadow: none !important;
  border: none !important;
}

.chatBottomSheet input::placeholder {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 400;
  line-height: 29.9px;
  letter-spacing: 0.03em;
  text-align: left;
  color: rgba(149, 165, 166, 1);
}

.chatBottomSheet .sendButton i {
  color: rgba(149, 165, 166, 1);
}

.chatBottomSheet .sendButton i {
  color: rgba(18, 169, 24, 1) !important;
}

.chatMiddle {
  overflow-y: scroll;
  height: 45%;
  max-height: 100vh;
  min-height: 70vh;
}

.courseBox {
  background: rgba(255, 255, 255, 1);
  border-radius: 6px;
  border: 0.71px solid rgba(196, 196, 196, 1)
}

.courseBox .boxtop {
  display: flex;
  padding: 16px;
  padding-bottom: 3px !important;
  align-items: flex-start;
}

.courseBox .boxtop p {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.91px;
  letter-spacing: 0.03em;
  text-align: left;
  color: rgba(73, 73, 73, 1);

}

.courseBox .borderBox {
  border: 0.71px solid rgba(196, 196, 196, 1)
}

.courseBox .bottomboccourse {
  padding: 10px;
}

.courseBox {
  position: relative;
}

.recordedClasses .heartIcon {
  position: absolute;
  right: 6px;
  z-index: 9999;
  top: 10px;
}

.recordedClasses .bottomboccourse .recordButton {
  background: linear-gradient(0deg, #EE643C 58.97%, #F4933D 125.97%);
  font-family: Kanit;
  font-size: 13.75px;
  font-weight: 400;
  border-radius: 6px;
  line-height: 20.55px;
  letter-spacing: 0.02em;
  width: 135px !important;
  height: 32px !important;
  text-align: center;
  margin: 0px !important;
  color: rgba(255, 255, 255, 1);

}

.chatMiddle .messageImgChat {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  left: -32px;
  bottom: 25px;
}

.leftSideContent {
  max-width: 80%;
  position: relative;
  margin-left: 40px;
  padding: 10px;
}

.messageShow {
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(217, 217, 217, 1);
  border-top-right-radius: 10px;
  padding: 10px;
  margin-left: unset;
  margin-right: 40px !important;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.messageShow p {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 200;
  line-height: 26.91px;
  margin-bottom: 0px !important;
  text-align: left;
  color: rgba(94, 94, 94, 1);

}


.leftSideContent span {
  font-family: Kanit;
  font-size: 12px;
  font-weight: 300;
  line-height: 17.94px;
  color: rgba(149, 165, 166, 1);
  margin: 0px;
  letter-spacing: 0.03em;
  text-align: left;

}

.senderChatMessage .messageImgChat {
  right: -32px !important;
  left: unset !important;
}

.senderChatMessage .messageShow {
  border: 1px solid rgba(217, 217, 217, 1) !important;
  background: rgba(255, 250, 248, 1) !important;
  padding: 10px;
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.senderChatMessage span {
  text-align: right;
  width: 100%;
  margin-top: 6px;
  display: flex;
  justify-content: flex-end;
}

.senderChatMessage .messageShow {
  margin-left: 40px;
  margin-right: unset !important;
}

.doubtClearing {
  max-height: 100vh;
  overflow: hidden;
}

.showPepple {
  min-height: 70vh;
  overflow-y: scroll;
  max-height: 56vh;
}


::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: #F90;
  background-image: -webkit-linear-gradient(90deg,
      rgba(255, 255, 255, .2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, .2) 50%,
      rgba(255, 255, 255, .2) 75%,
      transparent 75%,
      transparent)
}

.profileButton {
  background: linear-gradient(0deg, #EE643C 58.97%, #F4933D 125.97%);


}

/* trainerSidebar */

.trainerSidebar .otherdetail p,
span {
  font-size: 16px !important;
}

.bottomDocButton {
  display: flex;
  margin-right: 10px;
  justify-content: flex-end;
  align-items: center;
}

.bottomDocButton button {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.25);
  background: rgba(255, 255, 255, 1);
  padding: 12px;
  margin: 0px 6px;
  border-radius: 6px;
  border: none;
}

.queriesBoxAssignment textarea {
  width: 100%;
  box-shadow: 0px 0px 43.5px 0px rgba(86, 88, 88, 0.25);
  background: rgba(255, 255, 255, 1);
  border: none;
  padding: 10px;
  border-radius: 10px;
}

.queriesBoxAssignment textarea:focus-visible {
  border: none !important;
}

.queriesBoxAssignment button {
  background: linear-gradient(0deg, #EE643C 58.97%, #F4933D 125.97%);
  margin-top: 18px;
  width: 130px;
  height: 35px;
  border: none;
  color: white;
  border-radius: 10px;
}

.assignmentBoxBgDesign {
  box-shadow: 0px 0px 32.8px 0px rgba(0, 0, 0, 0.25);
  background: rgba(0, 122, 255, 1);
  margin-right:10px;
  position: relative;
  border-radius: 13px;
}

.assignmentBoxBgDesign p {
  position: absolute;
  font-family: Kanit;
  font-size: 24px;
  font-weight: 400;
  top: 11px;
  color: rgba(255, 255, 255, 1);
  line-height: 32px;
  text-align: center;
}

.leaveAttendanceBox table .absent {
  color: rgba(236, 50, 55, 1);
}