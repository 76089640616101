/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .CircularProgressbar {
    height: 100px;
    margin-left: 30px;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap
  }

  .Register-sec {
    margin: 334px auto 20px auto;
  }

  .teachers img {
    height: auto;
    width: 100%;
  }

  .award1 {
    height: auto;
    width: 100%;
  }
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .Register-offer p {
    font-size: 20px;
    margin-top: 50px;
    margin-left: 30px;
  }

  .other-detail p {
    font-size: 12px;

  }

  .other-detail span {
    font-size: 12px;
  }

  .award2 img {
    margin-top: 32px
  }

  .Register-sec h4 {
    margin-top: 100px;
  }
}

@media (max-width: 375.98px) {
  .Register-sec {
    margin-top: 697px;
  }

  .enroll-process p {
    font-size: 35px;
  }

}

/* // abouts as  */

@media (max-width: 768px) {
  .About-sec1 .col-md-6 {
    width: 100%;
    margin-bottom: 20px;
    /* Add spacing between columns */
  }
}

@media (max-width: 991.98px) {
  .connectdoteven::after {
    content: url(../images/Ellipse2.png);
    position: absolute;
    top: 20px;
    right: -40px;
    z-index: -1;
  }

  .journey1 img {
    margin-top: 30px;
    margin-left: 14px;
    height: auto;
    width: 100px;
  }
}


@media (max-width: 1199.98px) {
  .connectdoteven::after {
    display: none;

  }

  .connectdotodd::after {
    display: none;

  }

  .journey-detail {
    position: relative;
    margin-top: 85px;
  }

  .journey1 img {
    margin-top: 30px;
    margin-left: 44px;
    height: auto;
    width: 100px;
  }
}


/* design respinsive */

/* .navbarSticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transition-delay: 1s;
  z-index: 3;
  background-color: white;
  z-index: 99999999999;
} */