.navbar-sec {
    border-bottom: 1px solid #d6d6d6;
    padding: 28px 43px 3px 40px;
}
  .navbar-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .navbar-basic {
    display: flex;
    align-items: center;
  }
  .Searchicon {
    width: 43px;
    height: 42px;
    border-radius: 61%;
    background-color: white;
    padding: 5px;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
}
.navbar-basic h3 {
    margin-right: 10px; /* Adjust the right margin as needed */
  }
 
  .navbar-info {
    display: flex;
    align-items: center;
  }
 
  .navbar-info img {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    margin: 0px 20px 0px 20px;
  }
  .navbar-info h3 {
    padding: 10px 20px 0px 20px;
    margin-bottom: 10px !important;
  }

  img.bells {
    height: 45px;
    width: 45px;
    padding: 5px;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    background: #FFF;
    border-radius: 30px;
}